.logo {
  float: left;
  width: 120px;
  height: 32px;
  margin: 8px;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.trigger:hover {
  color: #1890ff;
}

.site-layout {
  height: 100vh;
  overflow-y: hidden;
}

.header {
  height: 48px;
  background: #fff;
}
